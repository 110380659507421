.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: 0px;
  content: "✔";
  margin-right: 5px;
}

.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: 0px;
  content: "✖";
  margin-right: 5px;
}
