@use "sass:map";

$break-points: (
  xs: 0px,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px,
);
html {
  height: 100%;
}

body {
  height: 100%;
  background-color: white;
}

#root {
  height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @media (max-width: 599px) {
    height: auto;
  }
}

header {
  min-height: 100px;
}

.page {
  // background-image: url(../assets/veggies@2x.png);
  background-size: 100px 350px;
  flex: 1;
}

// .shadow-card {
//   box-shadow: 5px 4px 9px 0px rgba(81, 27, 178, 0.1);
//   border: 3px solid rgb(81, 27, 178, 0.1);
//   border-radius: 3px;
// }

// button.css-1yk19h8-MuiButtonBase-root-MuiTab-root.Mui-selected {
//   color: #ff8f00 !important;
//   width: 300px;
// }

div.MuiAvatar-root.MuiAvatar-circle.MuiChip-avatar.MuiChip-avatarColorSecondary.MuiAvatar-colorDefault {
  background-color: #ff8f00 !important;
}
// .makeStyles-headerMenu-40 {
//   display: flex;
//   align-items: center;
// }

.barContainer {
  position: relative;
  width: 100%;
  // margin: 50px auto;
  margin-bottom: 30px;
}
.barContainer .bar {
  height: 15px;
  overflow: hidden;
}
.barContainer .bar div {
  width: 100%;
  height: 100%;
  float: left;
}

.barContainer .bar .green {
  background-color: green;
  // width: 100%;
}
.barContainer .bar .yellow {
  background-color: yellow;
  // width: 100%;
}
.barContainer .bar .red {
  background-color: red;
  // width: 100%;
}
table,
th,
td {
  border: 2px solid #ddd;
}
table {
  border-collapse: collapse;
  width: 100%;
}
#tableHeading {
  border-left-style: hidden !important;
  border-top-style: hidden !important;
}
th,
td {
  padding: 15px;
}
td {
  width: 160px;
  color: #1e4620;
  font-weight: 500;
  text-align: center;
}
.drilLines {
  position: relative;
  margin: 0 auto;
  margin-left: 60px;
}
.drilLines::before {
  content: "";
  display: block;
  width: 50px;
  height: 5px;

  left: -60px;
  top: 40%;
  border-radius: 20px;
  position: absolute;
}
.drilLinesGreen::before {
  background: #388e3c;
}
.drilLineYellow::before {
  background: #ffe97d;
}
.drilLineRed::before {
  background: #dd2c00;
}
.ml100 {
  margin-left: 100px;
}

.main-box {
  display: flex;
  width: 100%;
  margin: 30px 0;
}
.striped-border {
  border-top: 6px dashed #93c47d;
  width: 40px;
  min-width: 40px;
  margin: auto 0;
  margin-right: 10px;
}
.striped-borderTwo {
  border-top: 3px dashed #45818e;
  width: 40px;
  min-width: 40px;
  margin: auto 0;
  margin-right: 10px;
}
.box {
  width: 50%;
  display: flex;
  justify-content: center;
}
.boxone {
  color: #1e4620;
}
table,
th,
td {
  border-bottom: 1px solid #000;
  border-collapse: collapse;
}
.PhoneInputInput {
  padding: 10px 0px;
  border: none;
  outline: none;
  font-size: 15px;
}
.topupBorder {
  border-bottom: 1px solid #000 !important;
}
.formBorder {
  border-bottom: 1px dotted #081e03 !important;
}
.PhoneInputCountry {
  font-size: 20px;
}
.formInput {
  width: calc(50% - 14px);
}

.postContent {
  line-height: 1.6;
}

.postContent img {
  width: 100%;
  box-sizing: border-box;
  border: 10px solid #f6f9fc;
  border-radius: 10px;
  box-shadow: 0 30px 15px -25px rgba(0,0,0,.3), 0 0 30px 0 rgba(0,0,0,.15);
  margin: 2em 0;
  height: 100%;
}


@media screen and (max-width: 600px) {
  .formInput {
    width: 100%;
  }
}
// @font-face {
//   font-family: "Coolvetica";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Coolvetica"), url("../assets/font.ttf") format("ttf");
// }
@font-face {
  font-family: Coolvetica;
  src: url(../assets/font.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;800;900&display=swap");
.review-section {
  display: flex;
  .hero {
    img {
      width: 100%;
    }
    @media screen and (max-width: map.get($break-points, "md")) {
      width: 100px;
    }
  }
  .review {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-size: 40px;
    line-height: 141%;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
    @media screen and (max-width: map.get($break-points, "lg")) {
      font-size: 30px;
    }
    @media screen and (max-width: map.get($break-points, "md")) {
      font-size: 20px;
    }
  }
  .name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 124%;
    color: #bb371a;
    position: relative;
    margin: 0;
    &:after {
      content: "";
      position: absolute;
      background: #949494;
      height: 1px;
      width: 147px;
      margin-left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .designation {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 124%;
    color: #292929;
    margin: 0;
  }
}
.video-section {
  background: url(../assets/video-background.png);
  h2 {
    font-family: "Coolvetica";
    font-weight: 400;
    font-size: 70px;
    line-height: 92%;
    color: #031716;
    max-width: 414px;
    margin: 0;
    margin-bottom: 30px;
    @media screen and (max-width: 1280px) {
      font-size: 20px;
    }

    @media screen and (max-width: map.get($break-points, "lg")) {
      font-size: 60px;
    }
    @media screen and (max-width: map.get($break-points, "md")) {
      font-size: 50px;
      max-width: 100%;
    }
    @media screen and (max-width: map.get($break-points, "sm")) {
      font-size: 40px;
      text-align: center;
    }
  }
  ul {
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 238.5%;
      color: #000000;
      padding-left: 30px;
      position: relative;
      @media screen and (max-width: 1280px) {
        font-size: 20px;
      }
      &:before {
        list-style-type: none;
        content: counter(item, decimal-leading-zero) " ";
        counter-increment: item;
        font-size: 18px;
        font-weight: 800;
        position: absolute;
        left: 0px;
      }
    }
  }
}

.free-trial-button {
  display: inline-block;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #32CD32;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  animation: pulsate 1.5s infinite;
  // margin right 30px
  margin-right: 15px;
}

@keyframes pulsate {
  0%, 100% {
    transform: scale(1);
    background-color: #32CD32;
    box-shadow: none;
  }
  50% {
    transform: scale(1.1);
    background-color: #32CD32;
    box-shadow: 0 0 8px rgba(128, 255, 128, 0.5);
  }
}

.buttonOutlined {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  background: transparent;
  border-radius: 1px;
  text-align: center;
  border: 1px solid #EBA83A;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 32px;
  padding-left: 32px;
  text-transform: none;
}

.buttonOutlined:hover {
  background: #EBA83A;
  color: #13302B;
}

#content h2 {
  color: #031716;
  font-size: 60px;
  font-family: 'Coolvetica';
  font-weight: 400;
  line-height: 96%;
  margin-bottom: 35px;
  margin-inline: auto;
}

#content img {
  width: 100%;
  height: auto;
}
